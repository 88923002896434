import { useTranslation } from "react-i18next";
import s from "./disclaimer-section.module.css";
import { Button, ButtonColor } from "shared/ui-kit/button/button";
import { routes } from "shared/routes";
import { Fade, Slide } from "react-awesome-reveal";

const licenseUrl = "https://durian.blender.org/about/";

export const DisclaimerSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root} id="disclaimer">
      <div className={s.container}>
        <Fade triggerOnce>
          <h3>{t("disclaimerSection.title")}</h3>
        </Fade>
        <Slide triggerOnce direction="up">
          <div>
            <p>
              {t("disclaimerSection.p1")} ({t("disclaimerSection.p1_1")}{" "}
              <a href={licenseUrl} className={s.link}>
                {licenseUrl}
              </a>
              )
            </p>
            <br />
            <p>{t("disclaimerSection.p2")}</p>
            <br />
            <p>{t("disclaimerSection.p3")}</p>
            <br />
            <p>{t("disclaimerSection.p4")}</p>
          </div>
        </Slide>
        <Slide triggerOnce direction="up">
          <Button
            href={routes.privacyPolicy}
            color={ButtonColor.AccentInverted}
          >
            {t("disclaimerSection.button")}
          </Button>
        </Slide>
      </div>
    </section>
  );
};
