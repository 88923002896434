import { useTranslation } from "react-i18next";
import s from "./features-section.module.css";
import phoneImage from "./assets/phone.png";
import { Slide } from "react-awesome-reveal";

export const FeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root} id="features">
      <div className={s.top}>
        <Slide triggerOnce direction="up">
          <h2>{t("featuresSection.title")}</h2>
        </Slide>
        <Slide triggerOnce direction="up">
          <p className={s.subtitle}>{t("featuresSection.subtitle")}</p>
        </Slide>
      </div>
      <div className={s.bottom}>
        <div className={s.bg} />
        <div className={s.bottomContainer}>
          <div className={s.left}>
            <ul>
              <li>
                <Slide triggerOnce direction="left">
                  {t("featuresSection.feature1")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="left">
                  {t("featuresSection.feature2")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="left">
                  {t("featuresSection.feature3")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="left">
                  {t("featuresSection.feature4")}
                </Slide>
              </li>
            </ul>
          </div>
          <Slide triggerOnce direction="up">
            <div className={s.phone}>
              <img alt="" src={phoneImage} />
            </div>
          </Slide>
          <div className={s.right}>
            <ul>
              <li>
                <Slide triggerOnce direction="right">
                  {t("featuresSection.feature5")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="right">
                  {t("featuresSection.feature6")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="right">
                  {t("featuresSection.feature7")}
                </Slide>
              </li>
              <li>
                <Slide triggerOnce direction="right">
                  {t("featuresSection.feature8")}
                </Slide>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
