import cn from "classnames";
import s from "./button.module.css";
import { HashLink } from "react-router-hash-link";

export enum ButtonColor {
  Default = "default",
  Main = "main",
  Accent = "accent",
  AccentInverted = "accentInverted",
}

export enum ButtonSize {
  Small = "small",
  Big = "big",
}

export const Button = ({
  href,
  children,
  color = ButtonColor.Default,
  size = ButtonSize.Small,
  withoutScale,
}: {
  href: string;
  children: string;
  color?: ButtonColor;
  size?: ButtonSize;
  withoutScale?: boolean;
}) => {
  return (
    <HashLink
      to={href}
      className={cn(s.root, {
        [s.withoutScale]: withoutScale,
        [s.accentColor]: color === ButtonColor.Accent,
        [s.accentInvertedColor]: color === ButtonColor.AccentInverted,
        [s.mainColor]: color === ButtonColor.Main,
        [s.big]: size === ButtonSize.Big,
      })}
      smooth
    >
      {children}
    </HashLink>
  );
};
