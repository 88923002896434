import { useTranslation } from "react-i18next";
import s from "./supported-platforms-section.module.css";
import { Button, ButtonColor, ButtonSize } from "shared/ui-kit/button/button";
import android from "./assets/android.png";
import ios from "./assets/ios.png";
import samsungTv from "./assets/samsung_tv.png";
import lgTv from "./assets/lg_tv.png";
import { Fade, Slide, Zoom } from "react-awesome-reveal";

export const SupportedPlatformsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section id="about">
        <div className={s.container}>
          <Fade triggerOnce>
            <p className={s.text}>{t("supportedPlatformsSection.text")}</p>
          </Fade>
          <Zoom triggerOnce direction="up">
            <Button
              color={ButtonColor.Main}
              size={ButtonSize.Big}
              href="/#download"
              withoutScale
            >
              {t("supportedPlatformsSection.button")}
            </Button>
          </Zoom>
        </div>
      </section>
      <section className={s.platforms}>
        <Slide triggerOnce direction="left">
          <h3 className={s.title}>{t("supportedPlatformsSection.title")}</h3>
        </Slide>
        <Slide triggerOnce direction="right">
          <div className={s.icons}>
            <div>
              <img alt="android" src={android} />
            </div>
            <div>
              <img alt="ios" src={ios} />
            </div>
            <div>
              <img alt="samsung smart tv" src={samsungTv} />
            </div>
            <div>
              <img alt="lg smart tv" src={lgTv} />
            </div>
          </div>
        </Slide>
      </section>
    </>
  );
};
