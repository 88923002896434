import { createBrowserRouter } from "react-router-dom";
import { routes } from "shared/routes";
import { HomePage } from "./home-page/home-page";
import { PrivacyPolicyPage } from "./privacy-policy-page/privacy-policy-page";

export const router = createBrowserRouter([
  {
    path: routes.home,
    element: <HomePage />,
  },
  {
    path: routes.privacyPolicy,
    element: <PrivacyPolicyPage />,
  },
]);
