import { Header } from "widgets/header/header";
import { HomeSection } from "./sections/home-section/home-section";
import { FeaturesSection } from "./sections/features-section/features-section";
import { SupportedPlatformsSection } from "./sections/supported-platforms-section/supported-platforms-section";
import { DownloadSection } from "./sections/download-section/download-section";
import { DisclaimerSection } from "./sections/disclaimer-section/disclaimer-section";
import { Footer } from "widgets/footer/footer";
import { usePageTitle } from "shared/use-page-title";

export const HomePage = () => {
  usePageTitle("home");

  return (
    <>
      <Header />
      <HomeSection />
      <FeaturesSection />
      <SupportedPlatformsSection />
      <DownloadSection />
      <DisclaimerSection />
      <Footer />
    </>
  );
};
