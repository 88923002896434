import { useTranslation } from "react-i18next";
import s from "./header.module.css";
import { useState } from "react";
import cn from "classnames";
import { HashLink } from "react-router-hash-link";

export const Header = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const close = () => setOpened(false);

  return (
    <header className={s.root}>
      <div className={s.buttonContainer}>
        <button
          className={cn(s.menuButton, { [s.menuButtonOpened]: opened })}
          onClick={() => setOpened((v) => !v)}
        >
          <div />
          <div />
          <div />
        </button>
      </div>
      <nav className={cn(s.navigation, { [s.opened]: opened })}>
        <HashLink smooth className={s.link} to="/#home" onClick={close}>
          {t("navigation.home")}
        </HashLink>
        <HashLink smooth className={s.link} to="/#features" onClick={close}>
          {t("navigation.features")}
        </HashLink>
        <HashLink smooth className={s.link} to="/#about" onClick={close}>
          {t("navigation.about")}
        </HashLink>
        <HashLink smooth className={s.link} to="/#download" onClick={close}>
          {t("navigation.download")}
        </HashLink>
        <HashLink smooth className={s.link} to="/#disclaimer" onClick={close}>
          {t("navigation.disclaimer")}
        </HashLink>
      </nav>
    </header>
  );
};
