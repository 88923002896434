import { useTranslation } from "react-i18next";
import s from "./footer.module.css";
import { ReactComponent as EnvelopeIcon } from "./assets/envelope.svg";
import { ReactComponent as FacebookIcon } from "./assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "./assets/instagram.svg";
import { ReactComponent as TelegramIcon } from "./assets/telegram.svg";

const email = "support@playberry.cc";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={s.root}>
      <section className={s.left}>
        <h4 className={s.title}>{t("footer.contactUs")}</h4>
        <div className={s.body}>
          <EnvelopeIcon className={s.icon} />
          <a className={s.link} href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </section>
      <section className={s.right}>
        <h4 className={s.title}>{t("footer.socials")}</h4>
        <div className={s.body}>
          <a href="/#">
            <FacebookIcon className={s.social} />
          </a>
          <a href="/#">
            <InstagramIcon className={s.social} />
          </a>
          <a href="/#">
            <TelegramIcon className={s.social} />
          </a>
        </div>
      </section>
    </footer>
  );
};
