import { useTranslation } from "react-i18next";
import s from "./download-section.module.css";
import img from "./assets/img.jpeg";
import { Button, ButtonColor, ButtonSize } from "shared/ui-kit/button/button";
import { Slide, Zoom } from "react-awesome-reveal";
import { appStoreLink, playStoreLink } from "shared/external-links";

export const DownloadSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root} id="download">
      <div className={s.left}>
        <Slide triggerOnce direction="down">
          <h3 className={s.title}>{t("downloadSection.text")}</h3>
        </Slide>
        <div className={s.buttons}>
          <Zoom triggerOnce direction="left">
            <Button
              href={appStoreLink}
              withoutScale
              color={ButtonColor.Main}
              size={ButtonSize.Big}
            >
              {t("downloadSection.appStore")}
            </Button>
          </Zoom>
          <Zoom triggerOnce direction="right">
            <Button
              href={playStoreLink}
              withoutScale
              color={ButtonColor.Main}
              size={ButtonSize.Big}
            >
              {t("downloadSection.playStore")}
            </Button>
          </Zoom>
        </div>
      </div>
      <div className={s.right}>
        <img alt="" src={img} />
      </div>
    </section>
  );
};
