import { useTranslation } from "react-i18next";
import bg from "./assets/bg.mp4";
import s from "./home-section.module.css";
import { Button, ButtonColor } from "shared/ui-kit/button/button";
import { Slide, Zoom } from "react-awesome-reveal";
import { appStoreLink, playStoreLink } from "shared/external-links";

export const HomeSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root} id="home">
      <video autoPlay muted loop>
        <source src={bg} type="video/mp4" />
      </video>
      <div className={s.filter} />
      <div className={s.container}>
        <Zoom triggerOnce>
          <h1>{t("homeSection.title")}</h1>
        </Zoom>
        <p className={s.subtitle}>{t("homeSection.subtitle")}</p>
        <Slide triggerOnce direction="up">
          <div className={s.buttons}>
            <Button href={appStoreLink}>{t("homeSection.appStore")}</Button>
            <Button href={playStoreLink} color={ButtonColor.Accent}>
              {t("homeSection.playStore")}
            </Button>
          </div>
        </Slide>
      </div>
    </section>
  );
};
