import { Footer } from "widgets/footer/footer";
import s from "./privacy-policy-page.module.css";
import { useTranslation } from "react-i18next";
import { ScrollRestoration } from "react-router-dom";
import { usePageTitle } from "shared/use-page-title";

export const PrivacyPolicyPage = () => {
  usePageTitle("privacyPolicy");

  return (
    <>
      <ScrollRestoration />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <main className={s.root}>
      <h1 className={s.title}>{t("privacyPolicy.title")}</h1>
      <section>
        <p>
          <strong>{t("privacyPolicy.p1")}</strong>
        </p>
        <p>{t("privacyPolicy.p2")}</p>
        <p>{t("privacyPolicy.p3")}</p>
        <p>{t("privacyPolicy.p4")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.informationCollection.title")}</h6>
        <p>{t("privacyPolicy.informationCollection.p1")}</p>
        <p>{t("privacyPolicy.informationCollection.p2")}</p>
        <p>{t("privacyPolicy.informationCollection.p3")}</p>
        <ul>
          <li>
            <a href="https://www.google.com/policies/privacy/">
              {t("privacyPolicy.informationCollection.googlePlayServices")}
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h6>{t("privacyPolicy.logData.title")}</h6>
        <p>{t("privacyPolicy.logData.p")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.cookies.title")}</h6>
        <p>{t("privacyPolicy.cookies.p1")}</p>
        <p>{t("privacyPolicy.cookies.p2")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.serviceProviders.title")}</h6>
        <p>{t("privacyPolicy.serviceProviders.p1")}</p>
        <ul>
          <li>{t("privacyPolicy.serviceProviders.li1")}</li>
          <li>{t("privacyPolicy.serviceProviders.li2")}</li>
          <li>{t("privacyPolicy.serviceProviders.li3")}</li>
          <li>{t("privacyPolicy.serviceProviders.li4")}</li>
        </ul>
        <p>{t("privacyPolicy.serviceProviders.p2")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.security.title")}</h6>
        <p>{t("privacyPolicy.security.p")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.externalLinks.title")}</h6>
        <p>{t("privacyPolicy.externalLinks.p")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.childrenPrivacy.title")}</h6>
        <p>{t("privacyPolicy.childrenPrivacy.p")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.changes.title")}</h6>
        <p>{t("privacyPolicy.changes.p")}</p>
      </section>
      <section>
        <h6>{t("privacyPolicy.contactUs.title")}</h6>
        <p>{t("privacyPolicy.contactUs.p")}</p>
      </section>
    </main>
  );
};
